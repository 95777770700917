<template>
  <el-dialog :visible.sync="visible">
    <template #title>
      <div class="title">{{ language == "CN" ? "联系我们" : "Contact Us" }}</div>
    </template>
    <div class="infos">
      <div class="container">
        <div class="label-CN">业务咨询</div>
        <div class="label-EN">Domestic business consulting</div>
        <div class="telephone" v-if="detail.bizDomesticPhone != '' && detail.bizDomesticPhone != undefined">
          <div v-for="item, index in detail.bizDomesticPhone.split(',')" :key="index">
            <i class="el-icon-phone"></i>
            <span class="text">{{ item }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="container">
        <div class="label-CN">国际业务咨询</div>
        <div class="label-EN">International Business Consulting</div>
        <div class="telephone">
          <i class="el-icon-phone"></i>
          <span class="text">{{detail.bizAbroadPhone}}</span>
        </div>
      </div> -->
    </div>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { Modal } from "ant-design-vue";

export default {
  name: "ContactUsMiniModal",
  data() {
    return {
      visible: false,
    }
  },

  computed: {
    language() {
      return this.$store.getters.language
    },
    detail() {
      console.log('逗号分割', this.$store.getters.businessConfig.bizDomesticPhone);
      return this.$store.getters.businessConfig
    }
  },
  methods: {
    show() {
      this.visible = true
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 280px;
  margin-top: 80px !important;

  .el-dialog__header {
    padding: 16px;
    padding-bottom: 0;
  }

  .el-dialog__body {
    padding-top: 12px;
    padding: 16px;
  }
}

.title {
  font-family: 'Source Han Sans CN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
}

.infos {
  //border: #E8E9EB 1px solid;
  margin-top: 8px;

  .container {
    padding: 12px;
    //border-bottom: #E8E9EB 1px solid;
    background: #F6F7F9;
    margin-bottom: 8px;

    &:last-child {
      border-bottom: none;
    }

    .label {
      &-CN {
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &-EN {
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #979899;
      }
    }

    .telephone {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: #BF9660;

      .text {
        margin-left: 4px;
      }
    }
  }
}
</style>
