<template>
  <div class="languageSwitcher">
    <span class="btn" :class="{ selected: language == 'CN' }" @click="handleChangeLanguage('CN')">中</span>
    <!-- <span class="divider"></span> -->
    <span style="margin:0 5px">|</span>
    <span class="btn" :class="{ selected: language == 'EN' }" @click="handleChangeLanguage('EN')">EN</span>
  </div>
</template>

<script>
export default {
  name: "Language",
  data() {
    return {}
  },
  computed: {
    language() {
      console.log('ressssssss', this.$store.getters.language);
      return this.$store.getters.language;
    }
  },
  methods: {
    handleChangeLanguage(language) {
      this.$store.dispatch("ChangeLanguage", language)
      localStorage.setItem("currentLanguage", language)
    }
  },
}
</script>

<style scoped>
.languageSwitcher {
  width: 73px;
  height: 30px;
  padding: 4px 12px;
  background: #BF9660;
  border-radius: 55px;
  font-family: 'Source Han Sans CN';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);



}

.btn {
  cursor: pointer;
}

.divider {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  display: inline-block;
  height: 12px;
  margin: 0 8px;
}

.selected {
  color: #FFFFFF;
}
</style>
