<template>
  <div class="GlobalHeader">
    <div class="header">
      <div class="left">
        <div class="name">温州年德包装有限公司</div>
        <!-- <logo-mini /> -->
      </div>
      <div class="right">
        <language-mini v-if="languageSwitch == 1" />
        <search-mini @open="$refs.MenusMini.showMenus = false" ref="SearchMini" />
        <menus-mini ref="MenusMini" @openSearch="$refs.SearchMini.showSearch()" />
      </div>
    </div>
    <div class="headerCarousel">
      <!-- <header-carousel-mini :height="height"/> -->
    </div>
  </div>
</template>

<script>
import LogoMini from "@/components/header/modules/LogoMini.vue";
import LanguageMini from "@/components/header/modules/LanguageMini.vue";
import SearchMini from "@/components/header/modules/SearchMini.vue";
import MenusMini from "@/components/header/modules/MenusMini.vue";
import HeaderCarouselMini from "@/components/header/modules/HeaderCarouselMini.vue";
import {getAction} from "@/api/manage";


export default {
  name: "GlobalHeaderMini",
  components: { HeaderCarouselMini, MenusMini, SearchMini, LogoMini, LanguageMini },
  data() {
    return {
      languageSwitch: 0
    }
  },
  computed: {
    height() {
      // let home =window.screen.width /1920*800+"px"
      let home = 211 / 37.5 + "rem"
      // let others = window.screen.width /1920*600+"px"
      let others = 161 / 37.5 + "rem"
      return (this.$route.meta.type == 1 ? home : others)
    },
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    getInfos() {
      this.languageSwitch = 0
      getAction('api/config/getConfigInfo', { configRange: 'CO' }).then(res => {
        if (res.success) {
          this.languageSwitch = res.result.languageSwitch
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.GlobalHeader {
  font-size: 0;
  width: 100%;

  .header {
    //padding: 0 180px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-shadow: 1px 1px 10px 1px rgba(204, 204, 204, 0.46);
    //margin-bottom: 10px;
    background: #FFFFFF;

    .right {
      display: flex;
      align-items: center;
    }
  }

  .name {
    font-family: PangMenZhengDao;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #BF9660;
  }
}</style>
