<template>
  <div class="languageSwitcher">
    <span @click="handleChangeLanguage">{{language=="CN"?"中":"EN"}}</span>
  </div>
</template>

<script>
export default {
  name: "LanguageMini",
  data() {
    return {}
  },
  computed: {
    language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleChangeLanguage(){
      let language = this.language=="CN"?"EN":"CN"
      this.$store.dispatch("ChangeLanguage",language)
      localStorage.setItem("currentLanguage",language)
    }
  },
}
</script>

<style lang="less" scoped>
.languageSwitcher {
  color: #BF9660  ;
  font-family: 'Source Han Sans CN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
</style>
